<template>
  <div class="jobForm">
    <form id="jobForm" class="jobForm-form form" @submit.prevent="sendForm" novalidate>
      <label needed for="name">
        <span>{{ form.name.label }}</span>
        <input name="name" @keyup="validateInput" type="text" data-type="name" :placeholder="form.name.placeholder" />
      </label>
      <label needed for="lastName">
        <span>{{ form.lastName.label }}</span>
        <input name="lastName" @keyup="validateInput" type="text" data-type="name" :placeholder="form.lastName.placeholder" />
      </label>
      <label needed for="email">
        <span>{{ form.email.label }}</span>
        <input name="email" @keyup="validateInput" type="email" :placeholder="form.email.placeholder" />
      </label>
      <label needed for="phone">
        <span>{{ form.phone.label }}</span>
        <input name="phone" @keyup="validateInput" type="number" data-type="phone" :placeholder="form.phone.placeholder" />
      </label>
      <label needed for="curriculum">
        <span>{{ form.curriculum.label }}</span>
        <input
          @change="validateInput"
          name="curriculum"
          type="file"
          accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          data-type="phone"
          :placeholder="form.curriculum.placeholder"
        />
      </label>
      <label for="page">
        <span>{{ form.page.label }}</span>
        <input name="page" type="url" :placeholder="form.page.placeholder" />
      </label>
      <div class="jobForm-buttons">
        <button type="cancel" @click="$emit('closeForm')">{{ form.buttons.cancel }}</button>
        <button type="submit">{{ form.buttons.send }}</button>
      </div>
    </form>
  </div>
</template>
<script>
import alerts from "../../assets/utils/swalAlerts";
export default {
  props: { vacancy: String },
  methods: {
    async sendForm({ target }) {
      const isValid = this.$validateOG.validateForm("#jobForm");
      if (isValid) {
        const { isConfirmed } = await alerts.dataSendConfirm();
        if (isConfirmed) {
          alerts.dataSending();
          const formData = new FormData(target);
          formData.append("vacancy", this.vacancy);
          const { data } = await this.$api.post(`${process.env.VUE_APP_API_HOST}/jobs/postulation`, formData);
          data.success ? alerts.dataSendSuccess() : alerts.dataSendError();
          target.reset();
          this.$emit("closeForm");
        }
      }
    },
    validateInput({ srcElement }) {
      const type = srcElement.type === "text" ? srcElement.dataset.type : srcElement.type;
      this.$validateOG[type](srcElement);
    },
  },
  computed: {
    form() {
      return this.$locale.object["jobs-modal-form"];
    },
  },
};
</script>
<style lang="scss">
.jobForm {
  margin-top: 14px;
  span {
    color: $tundora;
  }
  &-buttons {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    button:hover {
      opacity: 0.5;
      box-shadow: 4px 4px 4px rgba(68, 68, 68, 0.3);
    }
  }

  @media (min-width: $tablet_width) {
  }
  @media (min-width: $desktop_width) {
    label:not([manual]) {
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
