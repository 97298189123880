<template>
  <div class="jobModal" @click="handleModal">
    <div class="jobModal-content customBar">
      <div class="jobModal-closeIcon" @click="$emit('closeForm')">
        <iconic name="close_x" class="jobModal-close" />
      </div>
      <div class="jobModal-info">
        <div class="jobModal-jobData">
          <h3 class="jobOffer-title special-title">{{ jobOffer.title }}</h3>
          <small class="jobOffer-location">{{ jobOffer.location }}</small>
          <div class="jobOffer-desc">
            <p class="jobOffer-desc-title">{{ data.description }}</p>
            <p class="jobOffer-desc-message" v-html="jobOffer.description"></p>
          </div>
          <div class="jobOffer-reqs">
            <p class="jobOffer-reqs-title">{{ data.requirements }}</p>
            <ul class="jobOffer-reqs-list">
              <li class="jobOffer-reqs-req" v-for="(req, idx) in jobOffer.requirements" :key="idx">{{ req }}</li>
            </ul>
          </div>
        </div>
        <div class="jobModal-formData">
          <div class="jobOffer-message">
            <p>
              {{ data.message.light }}&nbsp;
              <strong>{{ data.message.bold }}</strong>
            </p>
          </div>
          <JobForm :vacancy="jobOffer.title" @closeForm="$emit('closeForm')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JobForm from "./JobForm.vue";
export default {
  components: { JobForm },
  props: { jobOffer: Object },
  methods: {
    handleModal({ path }) {
      const isInModal = path.some((el) => el.classList?.contains("jobModal-content"));
      !isInModal ? this.$emit("closeForm") : "";
    },
  },
  computed: {
    data() {
      return this.$locale.object["jobs-modal-data"];
    },
  },
};
</script>
<style lang="scss">
.jobModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  &-content {
    position: fixed;
    top: 5%;
    bottom: 5%;
    left: 3%;
    right: 3%;
    z-index: 11;
    background: $mercury;
    box-shadow: 6px 6px 8px rgba(102, 102, 102, 0.3), inset 0px 0px 8px $mercury;
    border-radius: $mradius;
    padding: 45px 24px 30px;
    color: black;
    overflow-y: scroll;
  }
  &-close {
    position: absolute;
    top: 3%;
    right: 5%;
    cursor: pointer;
    z-index: 15;
  }
  .jobOffer {
    &-title {
      font-size: 24px;
      margin-bottom: 0;
    }
    &-location {
      font-size: 12px;
    }
    &-desc {
      margin-top: 18px;
      &-title {
        font-size: 14px;
        font-weight: bold;
      }
      &-message {
        margin-top: 8px;
        font-size: 14px;
      }
    }
    &-reqs {
      font-size: 14px;
      margin-top: 18px;
      &-title {
        font-weight: bold;
      }
      &-list {
        margin-top: 8px;
        list-style: none;
        list-style-type: none;
      }
      &-req {
        position: relative;
        padding-left: 10px;
      }
      &-req:before {
        content: "";
        top: 9px;
        left: 0;
        position: absolute;
        display: inline-block;
        width: 2px;
        height: 2px;
        background: black;
        border-radius: 50%;
        margin: auto;
      }
    }
    &-message {
      font-size: 14px;
      margin-top: 18px;
    }
  }
  @media (min-width: $tablet_width) {
    &-content {
      padding: 50px 48px 30px;
    }
    &-close {
      top: 5%;
      right: 4%;
    }
  }
  @media (min-width: $desktop_width) {
    &-content {
      padding: 54px 54px 20px;
      height: 680px;
      max-width: 1100px;
      margin: 0 auto;
    }
    &-info {
      display: grid;
      gap: 74px;
      grid-template-columns: 1fr 1fr;
    }
    .jobOffer {
      &-title {
        font-size: 36px;
      }
      &-desc {
        * {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &-reqs {
        line-height: 24px;
        font-size: 16px;
      }
    }
    [inputError] {
      padding: 0px 10px;
      position: absolute;
    }
    label:not([manual]):not(:first-child):not(:empty) {
      display: block;
      margin-top: 20px;
    }
  }
}
</style>
