<template>
  <main class="jobs-container" v-if="this.$locale['object']['jobs-main-banner']">
    <GlobalMainBanner :bannerData="$locale['object']['jobs-main-banner']" />
    <JobOffers />
  </main>
  <Spinner v-else />
</template>

<script>
import GlobalMainBanner from "../global/GlobalMainBanner.vue";
import JobOffers from "./JobOffers.vue";

export default {
  components: { GlobalMainBanner, JobOffers },
  async beforeMount() {
    if (!this.$locale["object"]["jobs-main-banner"]) await this.$store.dispatch("updateLocal");
  },
};
</script>

<style lang="scss"></style>
