<template>
  <div class="jobOffer">
    <div class="jobOffer-content">
      <h3 class="jobOffer-title special-title">{{ jobOffer.title }}</h3>
      <small class="jobOffer-location">{{ jobOffer.location }}</small>
      <p class="jobOffer-desc" v-html="jobOffer.description"></p>
      <button class="jobOffer-button" @click="activeModal = true">
        {{ $locale["jobs-more-button"] }}
        <iconic name="circle_plus" />
      </button>
    </div>
    <JobModal @closeForm="activeModal = false" v-if="activeModal" :jobOffer="jobOffer" />
  </div>
</template>
<script>
import JobModal from "./JobModal.vue";

export default {
  components: { JobModal },
  props: { jobOffer: Object },
  data() {
    return {
      activeModal: false,
    };
  },
};
</script>
<style lang="scss">
.jobOffer {
  &-location {
    font-weight: bold;
  }
  &-desc {
    font-family: $font_text;
    margin: 8px 0px;
  }
  &-button {
    font-family: $sec-font;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: $tundora;
    padding: 5px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    svg {
      height: 27px;
      width: 27px;
      color: $tundora;
    }
  }
  @media (min-width: $tablet_width) {
    &-content {
      max-width: 529px;
    }
  }
  @media (min-width: $desktop_width) {
    &-title {
      font-size: 24px;
      // max-width: 616px;
    }
  }
  @media (min-width: $tv_width) {
    &-content {
      max-width: 620px;
    }
  }
}
</style>
