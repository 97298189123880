<template>
  <section class="jobOffers">
    <div class="jobOffers-content main">
      <div class="jobOffers-header">
        <h2 class="jobOffers-title">{{ $locale["jobs-header"] }}</h2>
      </div>
      <div class="jobOffers-list">
        <JobOffer v-for="jobOffer in $locale.jobs" :key="jobOffer._id" :jobOffer="jobOffer" />
      </div>
    </div>
  </section>
</template>
<script>
import JobOffer from "./JobOffer.vue";
export default {
  components: { JobOffer },
};
</script>
<style lang="scss">
.jobOffers {
  &-content {
    font-family: $sec_font;
    color: $shark;
    padding: 58px 20px;
  }
  &-header {
    border-bottom: 1px solid $shark;
    padding-bottom: 8px;
    margin-bottom: 38px;
    max-width: 618.5px;
  }
  &-title {
    font-size: 24px;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  @media (min-width: $tablet_width) {
    &-content {
      padding: 78px 20px 48px;
    }
    &-list {
      align-items: flex-end;
    }
    &-header {
      margin-bottom: 54px;
    }
  }
  @media (min-width: $desktop_width) {
    &-content {
      padding: 80px 158px 59px;
    }
    &-header {
      padding-bottom: 13px;
      margin-bottom: 62px;
    }
    &-title {
      font-size: 36px;
    }
  }
}
</style>
